import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as TrashIcon } from "../../../assets/icons/lixeira.svg";

import ButtonAmount from "../../atoms/button-amount";
import OutOfStockTag from "../../atoms/tag-out-of-stock";

import {
  increaseItemAmount,
  decreaseItemAmount,
  removeItemFromCart,
} from "../../../store/cart";
import { formatPrice } from "../../../util/format";

import styles from "./styles.module.scss";

export default function CartProductCard(props) {
  const dispatchEvent = useDispatch();

  const cartItemsCount = useSelector(({ cart }) => cart.items.length);
  const product = useSelector(({ products }) =>
    products.items.find((prod) => prod.productId === props.item.productId)
  );

  const [isDeleting, setIsDeleting] = useState(false);

  const productImageSrc = product.images.product[0].imageUrl;
  console.log("teste aqui: ", productImageSrc);
  const productImageAlt = product.images.product[0].imageText;
  const productPrice = product.commertialOffer.price;
  const { amount, availableQuantity, label } = props.item;
  
  const deletingClassName = isDeleting ? styles.deleting : "";
  const outOfStock = !props.item.isAvailable;
  const isDecreaseDisabled = amount < availableQuantity ? false : true;
  
  const sizeLabel = label.split(" ")
  const hasTwoTexts = sizeLabel.length > 1
  const twoTextsClassName = hasTwoTexts ? styles.reduceLabelSize : ""

  function onIncrease() {
    dispatchEvent(increaseItemAmount(props.item));
  }

  function onDecrease() {
    dispatchEvent(decreaseItemAmount(props.item));
  }

  function removeItem() {
    const delay = cartItemsCount > 1 ? 800 : 300;
    setIsDeleting(true);
    setTimeout(() => {
      dispatchEvent(removeItemFromCart(props.item));
    }, delay);
  }

  return (
    <article
      className={`${styles.card} ${props.className || ""} ${deletingClassName}`}
    >
      <img
        src={productImageSrc}
        className={styles.image}
        alt={productImageAlt}
      />
      <div className={styles.infosSection}>
        <h3 title={product.productName} className={styles.title}>
          {product.productName}
        </h3>

        {outOfStock ? (
          <OutOfStockTag className={styles.outOfStock}></OutOfStockTag>
        ) : (
          <data className={styles.price} value={productPrice}>
            {formatPrice(productPrice)}
          </data>
        )}
        <div className={styles.controlsWrapper}>
          <div className={styles.sizeLabel}>
              {sizeLabel.map(( text, index ) => {
                const slash = index < sizeLabel.length - 1 ? '/' : ''
                return <div key={text} className={`${styles.sizeLabelLetter} ${twoTextsClassName}`}>{text}{slash}</div> 
              })}
          </div>
          <ButtonAmount
            className={styles.buttonAmount}
            amount={amount}
            onIncrease={onIncrease}
            onDecrease={onDecrease}
            isDecreaseDisabled={isDecreaseDisabled}
          />
        </div>
      </div>
      <button onClick={removeItem} className={styles.deleteButton}>
        <TrashIcon />
      </button>
    </article>
  );
}
