import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCategories } from "../../services/Categories";

const namespace = "categories";

const getCategoriesList = createAsyncThunk(
  `${namespace}/getCategoriesList`,
  async () => {
    const response = await getCategories();
    return response.data;
  }
);

const slice = createSlice({
  name: namespace,
  initialState: {
    items: [],
    requesting: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCategoriesList.fulfilled, (state, action) => {
      state.items = action.payload;
      state.requesting = false
    });
    builder.addCase(getCategoriesList.pending, (state) => {
      state.requesting = true
    });
    builder.addCase(getCategoriesList.rejected, (state) => {
      state.requesting = false
      console.error("Houve um erro na requisição 'getCategoriesList'");
    });
  },
});

export const { reducer } = slice;

export { getCategoriesList };

export default slice;
