import styles from './styles.module.scss'

export default function CallToActionButton(props){
  return (
    <button 
      {...props}
      className={`${styles.button} ${props.className || ''}`} 
      type={props.type || 'button'}
    />
  );
}