import { createSlice } from '@reduxjs/toolkit'

const namespace = 'virtualTour'

const slice = createSlice({
  name: namespace,
  initialState: {
    tourStarted: false
  },
  reducers: {
    startTour: (state) => {
      state.tourStarted = true
    }
  }
});

export const { startTour } = slice.actions;
export const { reducer } = slice;
export default slice;