import { createSlice } from '@reduxjs/toolkit'

const namespace = 'radio'

const slice = createSlice({
  name: namespace,
  initialState: {
    playing: false,
    lastRadioSatus: false,
  },
  reducers: {
    setLastRadioStatus: (state, action) => {
      state.lastRadioSatus = action.payload
    },
    setPlaying: (state, action) => {
      state.playing = action.payload
    }
  }
});

export const { setPlaying, setLastRadioStatus } = slice.actions;
export const { reducer } = slice;
export default slice;