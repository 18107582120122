import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCollections } from "../../services/Collections";

const namespace = "collections";

const getCollectionsList = createAsyncThunk(
  `${namespace}/getCollectionsList`,
  async () => {
    const response = await getCollections();
    return response.data;
  }
);

const slice = createSlice({
  name: namespace,
  initialState: {
    items: [],
    requesting: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCollectionsList.fulfilled, (state, action) => {
      state.items = action.payload;
      state.requesting = false;
    });
    builder.addCase(getCollectionsList.pending, (state) => {
      state.requesting = true;
    });
    builder.addCase(getCollectionsList.rejected, (state) => {
      state.requesting = false;
      console.error("Houve um erro na requisição 'getCollectionsList'");
    });
  },
});

export const { reducer } = slice;

export { getCollectionsList };

export default slice;
