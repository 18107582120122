import styles from './styles.module.scss'

export default function ButtonAmount(props){
  return (
    <div className={`${styles.wrapper} ${props.className || ''}`}>
      <button type="button" onClick={props.onDecrease} className={styles.button}>-</button>
      <data className={styles.amount} value={props.amount}>{props.amount}</data>
      <button type="button" onClick={props.onIncrease} className={styles.button} disabled={props.isDecreaseDisabled}>+</button>
    </div>
  )
}