import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { getGallery } from "../../services/Gallery";

const namespace = "gallery";

const getGalleryInfo = createAsyncThunk(
  `${namespace}/getGalleryInfo`,
  async () => {
    const response = await getGallery();
    return response.data;
  }
);

const slice = createSlice({
  name: namespace,
  initialState: {
    taxonomies: {},
    filterDefault: {},
    requesting: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getGalleryInfo.fulfilled, (state, action) => {
      const { taxonomies, filterDefault } = action.payload
      state.taxonomies = taxonomies;
      state.filterDefault = filterDefault;
      state.requesting = false;
    });
    builder.addCase(getGalleryInfo.pending, (state) => {
      state.requesting = true;
    });
    builder.addCase(getGalleryInfo.rejected, (state) => {
      state.requesting = false;
      console.error("Houve um erro na requisição 'getGalleryInfo'");
    });
  },
});

export const { reducer } = slice;

export { getGalleryInfo };

export default slice;
