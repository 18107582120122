import { useRef, useMemo } from "react";
import { useDispatch } from "react-redux";

import { setPlaying } from "../store/radio";

export default function useRadio(
  source = "https://visual.shoutca.st/stream/radioibiza"
) {
  const dispatchEvent = useDispatch();

  const audio = useMemo(() => new Audio(source), [source]);
  const radio = useRef(audio);

  radio.current.volume = 0.1;

  function play(e) {
    radio.current.play();
    dispatchEvent(setPlaying(true));
  }
  function pause(e) {
    radio.current.pause();
    dispatchEvent(setPlaying(false));
  }

  return {
    radio,
    play,
    pause,
  };
}
