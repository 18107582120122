import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { showProduct } from "../../../store/products";

import OutOfStockTag from "../../atoms/tag-out-of-stock";

import { formatPrice } from "../../../util/format";

import styles from "./styles.module.scss";

export default function GalleryProductCard(props) {
  const dispatchEvent = useDispatch();

  const [imageIndex, setImageIndex] = useState(0);

  const { productName, images, productId, commertialOffer, sizes } = props.product;
  const { imageUrl, imageSrc } = images.product[imageIndex] || images.product[0] ;
  const { price, listPrice } = commertialOffer;
  
  const outOfStock = sizes?.filter(item => item.isAvailable === true).length ? false : true;

  function handleOnClick() {
    dispatchEvent(showProduct({ productId }));
  }
  function onMouseOver() {
    setImageIndex(1);
  }
  function onMouseLeave() {
    setImageIndex(0);
  }

  useEffect(() => {
    //preload second image for faster rendering
    const { imageUrl } = images.product.length > 1? images.product[1] : images.product[0];
    new Image().src = imageUrl;
  }, [images]);

  const showDiscount = price !== listPrice;
  const discountClassName = showDiscount ? styles.showDiscount : "";

  return (
    <article className={styles.card} onClick={handleOnClick}>
      <figure
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
        className={styles.figure}
      >
        <img title={productName} src={imageUrl} alt={imageSrc} />
      </figure>
      <h3 className={styles.productTitle}>{productName}</h3>

      {outOfStock ? (
        <OutOfStockTag className={styles.outOfStock}></OutOfStockTag>
      ) : (
        <div className={styles.prices}>
          <span className={`${styles.price} ${discountClassName}`}>
            {formatPrice(price)}
          </span>
          {showDiscount && (
            <span className={styles.fullPrice}>{formatPrice(listPrice)}</span>
          )}
        </div>
      )}
    </article>
  );
}
