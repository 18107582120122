import styles from './styles.module.scss'

export default function Dialog({
  className = '',
  onConfirm,
  message,
  icon,
  type = "success",
  buttonText = "continuar"
}) {
  
  const isWarning = type === "warning"
  const warningClassName = isWarning ? styles.warning : ''
  
  return (
    <div className={`${className} ${styles.dialog}`}>
      <p className={styles.text}>
        { message }
      </p>
      {Boolean(icon) && (
        <div className={styles.divIcon}>            
          {icon}            
        </div>
      )}
      <button className={`${styles.button} ${warningClassName}`} type='button' onClick={onConfirm}>
        { buttonText }
      </button>
    </div>
  );
}