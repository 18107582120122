import axios from "axios";

const getProductsByProductIds = async (productIdsArray) => {
  let url = `${process.env.REACT_APP_BACKEND_URL}/products/${productIdsArray}`;
  const response = await axios.get(url);
  return response;
};

const getPaginatedProductsByCompoundId = async (
  compoundId,
  page = 1,
  perPage = 10
) => {
  let url = `${process.env.REACT_APP_BACKEND_URL}/categories/${compoundId}?page=${page}&perPage=${perPage}`;
  const response = await axios.get(url);
  return response;
};

const getPaginatedProductsByCollectionId = async (
  collectionId,
  page = 1,
  perPage = 10
) => {
  let url = `${process.env.REACT_APP_BACKEND_URL}/collections/${collectionId}?page=${page}&perPage=${perPage}`;
  const response = await axios.get(url);
  return response;

};

const getPaginatedProducts = async (page = 1, perPage = 10) => {
  let url = `${process.env.REACT_APP_BACKEND_URL}/products?page=${page}&perPage=${perPage}`;
  const response = await axios.get(url);
  return response;
};

export {
  getProductsByProductIds,
  getPaginatedProductsByCompoundId,
  getPaginatedProductsByCollectionId,
  getPaginatedProducts
};
