import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Select from '../../atoms/select';
import Modal from '../../molecule/modal';
import GalleryProductCard from '../../molecule/card-product-gallery';

import { ReactComponent as CloseIcon } from '../../../assets/icons/fechar-pequeno.svg';

import loadingIcon from '../../../assets/gifs/loading.gif';

import { toggleGallery, getProducts, getProductsWithCompoundId, showRack, getProductsWithCollectionId } from '../../../store/products';

import styles from './styles.module.scss';

const getTaxonomyInfoFromFilterValue = (value) => {
  const infos = value.split('_')
  return {
    id: parseInt(infos[0]),
    taxonomyType: infos[1]
  }
}

export default function ProductsGallery({ noFilter = false, forceTaxonomy = false }){

  const dispatchEvent = useDispatch();

  const [ products, requesting, categories, collections, gallery ] = useSelector(({ products }) => {
    const { items, requesting, categories, collections, gallery } = products;
    return [ items, requesting, categories, collections, gallery ]
  });

  /* 
  ** aqui as taxonomias utilizadas são 
  ** definidas/puxadas-do-estado manualmente, 
  ** mas deveriam ser definidas/puxadas-do-estado 
  ** a partir do que vem do back-end 
  */
    const allCategories  = useSelector(({ categories }) => categories.items );
    const allCollections = useSelector(({ collections }) => collections.items );
    
    const { taxonomies, filterDefault } = useSelector(({ gallery }) => gallery );

    const galleryCategories = taxonomies.find(({ type }) => type === "category" ).items
    const galleryCollections = taxonomies.find(({ type }) => type === "collection" ).items

    const filterOptionsCategoriesList  = allCategories.filter(({ id }) => galleryCategories.includes(id));
    const filterOptionsCollectionsList = allCollections.filter(({ id }) => galleryCollections.includes(id));
    
    const filterOptionsMap = {
      collection: filterOptionsCollectionsList,
      category: filterOptionsCategoriesList
    }

    const productsListMap = {
      collection: collections,
      category: categories
    }

    const filterOptionsTaxonomiesList = [].concat(filterOptionsCategoriesList, filterOptionsCollectionsList);
    const filterOptions = filterOptionsTaxonomiesList.map((({id, taxonomyType, name}) => (
      {
        value: `${id}_${taxonomyType}`,
        label: name
      }
    )));
  /* */

  const isRack = Boolean(forceTaxonomy);

  const defaultFilterOption = filterOptionsMap[filterDefault.taxonomyType]?.find(({ id }) => id === filterDefault.taxonomyId)

  const filterInitialValue = isRack ? forceTaxonomy : defaultFilterOption

  const [ show, setShow ]                 = useState(false);
  const [ openFilter, setOpenFilter ]     = useState(false);
  const [ filterValue, setFilterValue ]   = useState(filterInitialValue);
  
  const selectComponentCurrentValue = { value: filterValue.id, label: filterValue.name }

  const [ internalPage, setInternalPage ] = useState(1);
  const [ rackInternalPage, setRackInternalPages ] = useState(1);
  const [ rackTotalPages, setRackTotalPages ] = useState(1);
  
  const productsRenderedPerPage         = 9;
  const isFiltered                      = Boolean(filterValue);
  const _internalPage                   = isRack ? rackInternalPage : internalPage;
  const allProductsPaginated            = products.slice(0, _internalPage * productsRenderedPerPage);
  
  const selectedTaxonomyProductsIdsList = productsListMap[filterValue.taxonomyType].find(({ id }) => id === filterValue.id)?.items || [];
  const filteredByTaxonomyProductsList  = products.filter(({ productId }) => selectedTaxonomyProductsIdsList.includes(productId));
  const filteredProductsPaginated       = filteredByTaxonomyProductsList.slice(0, _internalPage * productsRenderedPerPage);
  
  const productsToBeRendered            = isFiltered ? filteredProductsPaginated : allProductsPaginated;
  
  const noProducts                      = productsToBeRendered.length === 0 && !requesting;
  
  const headerTitle                     = isRack ? '' : 'Galeria';
  const showClassName                   = show ? styles.show : '';
  
  const { totalPages, page, perPage } = gallery;

    
  function closeGallery(){
    setShow(false);
    setTimeout(() => {
      dispatchEvent(toggleGallery());
      dispatchEvent(showRack(false));
    }, 300);
  }
  
  function galleryOnClick(e){ 
    e.stopPropagation();
    if(openFilter === true) setOpenFilter(false);
  }
  
  function onCategoryChange(selectCurrentValue){
    setInternalPage(1);
    const selected = getTaxonomyInfoFromFilterValue(selectCurrentValue)
    const selectedTaxonomy = filterOptionsTaxonomiesList.find(({ id, taxonomyType}) => {
      return selected.id === id && selected.taxonomyType === taxonomyType
    })
    if(selectedTaxonomy) setFilterValue(selectedTaxonomy);
  }

  const getProductsActionCreator = useCallback((args) => {
    const mapping = {
      category: getProductsWithCompoundId,
      collection: getProductsWithCollectionId
    }
    return mapping[filterValue.taxonomyType](args)
  }, [filterValue.taxonomyType]);

  function handleOnScroll(evt){
    const element           = evt.target;
    
    const { offsetHeight, scrollTop, scrollHeight, scrollWidth, clientWidth, offsetWidth, scrollLeft } = element;
    
    const isHorizontalScroll = scrollWidth > clientWidth;
    const reachOffset        = 250;
    const hasReachedBottom   = offsetHeight + scrollTop >= scrollHeight - reachOffset;
    const hasReachedRight    = offsetWidth + scrollLeft >= scrollWidth - reachOffset;
    const hasReachedEnd      = isHorizontalScroll ? hasReachedRight : hasReachedBottom;

    if(!hasReachedEnd || requesting) return;

    if(!isFiltered){
      const hasMoreToLoad   = page < totalPages;
      const shouldLoadMore  = hasMoreToLoad && !requesting;
      const hasMoreToRender = productsToBeRendered.length < products.length;

      if(shouldLoadMore) dispatchEvent(getProducts());
      if(hasMoreToRender) setInternalPage(internalPage + 1);
    }

    if(isFiltered){
      const numOfProductsInCategory = filterValue.quantity;
      const _totalPages             = isRack ? rackTotalPages : Math.ceil(numOfProductsInCategory / perPage);
      const hasMoreToLoad           = _internalPage < _totalPages;
      const shouldLoadMore          = hasMoreToLoad && !requesting;
      const hasMoreToRender         = productsToBeRendered.length < numOfProductsInCategory;
      const nextPage                = _internalPage + 1;
      
      if(!isRack && hasMoreToRender) setInternalPage(nextPage);
      
      if(shouldLoadMore){ 
        dispatchEvent(getProductsActionCreator({ 
          compoundId: filterValue.compoundId || undefined,
          id: filterValue.id || undefined,
          page: nextPage,
          perPage
        })).then(( response ) => {
          setRackInternalPages(response.payload.page)
          setRackTotalPages(response.payload.totalPages)
        });;
      }
    }
  }

  useEffect(() => {
    if(isFiltered) {
      dispatchEvent(getProductsActionCreator({ 
        compoundId: filterValue.compoundId || undefined,
        id: filterValue.id || undefined,
        page: 1
      })).then(( response ) => {
        setRackInternalPages(response.payload.page)
        setRackTotalPages(response.payload.totalPages)
      });;
    }
  }, [ dispatchEvent, forceTaxonomy, isRack , filterValue, getProductsActionCreator, isFiltered]);

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 10);
  }, [setShow]);
  
  return (
    <Modal onClick={closeGallery} className={`${styles.modal} ${showClassName}`}>
      <section onClick={galleryOnClick} className={styles.gallery}>
        <header className={styles.header}>
          <h2 className={styles.title}>{headerTitle}</h2>
          <button onClick={closeGallery} className={styles.closeButton}><CloseIcon/></button>
          <hr/>
        </header>
        {!noFilter && 
          
          <section className={styles.filterWrapper}>
            <Select 
              currentValue={selectComponentCurrentValue} 
              options={filterOptions} 
              onChange={onCategoryChange}
              open={openFilter}
              setOpen={setOpenFilter}
              className={styles.categorySelect}
            />
          </section>
        }
        {!noProducts && 
          <section onScroll={handleOnScroll} className={styles.productsListWrapper}>
            <ul className={styles.productsList}>
              {productsToBeRendered.map((product) => <li key={product.productId}><GalleryProductCard product={product}/></li> )}
            </ul>
          </section>
        }
        {requesting && 
          <div className={styles.loading}>
            <img src={loadingIcon} alt=""/>
          </div>
        }
        {noProducts && 
          <div className={styles.noProducts}>Não há produtos nessa categoria.</div>
        }
      </section>
    </Modal>
  )
}