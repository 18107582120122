window.dataLayer = window.dataLayer || []

export const events = {
  onBubbleAction: 'on_bubble_action',
  onBubbleOpen: 'on_bubble_open',
  onCartAdd: 'on_cart_add',
  onCheckoutDo: 'on_checkout_do',
  onCouponAction: 'on_coupon_action',
  onGalleryOpen: 'on_gallery_open',
  onGuidedTourStart: 'on_guided_tour_start',
  onProductOpen: 'on_product_open',
  onRackOpen: 'on_rack_open',
  onSoundOff: 'on_sound_off',
  onSoundOn: 'on_sound_on',
  onSplashScreenBackgroundClick: 'on_splash_screen_background_click',
  onSplashScreenCallToActionClick: 'on_splash_screen_call_to_action_click',
  onTourStart: 'on_tour_start',
  onVideoStart: 'on_video_start',
};

export default function useEventEmitter(){
  return function emitEvent(event, data){
    window.document.dispatchEvent(
      new CustomEvent(
        event,
        {
          detail: data
        }
      )
    )
  }
}

Object.values(events).forEach(event => {
  window.document.addEventListener(event, function(evt){
    window.dataLayer.push({
      event,
      ...evt.detail
    })
  })
});