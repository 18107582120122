import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  getProductsByProductIds,
  getPaginatedProductsByCompoundId,
  getPaginatedProductsByCollectionId,
  getPaginatedProducts,
} from "../../services/Products";

const namespace = "products";

const getProductWithProductId = createAsyncThunk(
  `${namespace}/getProductWithProductId`,
  async (productId) => {
    const response = await getProductsByProductIds(productId);
    return response.data;
  }
);

const getProductsWithCompoundId = createAsyncThunk(
  `${namespace}/getProductsWithCompoundId`,
  async (params, thunkAPI) => {
    const { products } = thunkAPI.getState();

    if (!params.page) params.page = products.gallery.page;
    if (!params.perPage) params.perPage = products.gallery.perPage;

    const response = await getPaginatedProductsByCompoundId(
      params.compoundId,
      params.page,
      params.perPage
    );
    return response.data;
  }
);

const getProductsWithCollectionId = createAsyncThunk(
  `${namespace}/getProductsWithCollectionId`,
  async (params) => {
    if (!params.page) params.page = 1;
    if (!params.perPage) params.perPage = 10;

    const response = await getPaginatedProductsByCollectionId(
      params.id,
      params.page,
      params.perPage
    );
    return response.data;
  }
);

const getProducts = createAsyncThunk(
  `${namespace}/getProducts`,
  async (params = {}, thunkAPI) => {
    const { products } = thunkAPI.getState();
    if (!params.page) params.page = products.gallery.page + 1;
    if (!params.perPage) params.perPage = products.gallery.perPage;

    const response = await getPaginatedProducts(params.page, params.perPage);
    return response.data;
  }
);

function handleNewProductsInsertion(oldList, newList) {
  newList.forEach((item) => {
    const index = oldList.findIndex(
      ({ productId }) => productId === item.productId
    );
    if (index >= 0) {
      oldList[index] = item;
    } else {
      oldList.push(item);
    }
  });

  return oldList;
}

const slice = createSlice({
  name: namespace,
  initialState: {
    items: [],
    categories: [],
    collections: [],
    gallery: {
      page: 0,
      totalPages: 0,
      perPage: 10,
    },
    displayingGallery: false,
    displaying: 0,
    displayingRack: 0,
    requesting: false,
    salesChannel: 1,
  },
  reducers: {
    showProduct: (state, action) => {
      state.displaying = action.payload.productId;
      state.displayingRack =
        action.payload.fallbackCategory || state.displayingRack;
    },
    toggleGallery: (state) => {
      state.displayingGallery = !state.displayingGallery;
    },
    showRack: (state, action) => {
      state.displayingRack = action.payload;
    },
  },
  extraReducers: (builder) => {
    // getProductWithProductId
    builder.addCase(getProductWithProductId.fulfilled, (state, action) => {
      state.items = handleNewProductsInsertion(
        state.items,
        action.payload.products
      );
      state.requesting = false;
    });
    builder.addCase(getProductWithProductId.pending, (state) => {
      state.requesting = true;
    });
    builder.addCase(getProductWithProductId.rejected, (state, action) => {
      state.requesting = false;
      console.error("Houve um erro na requisição 'getProductWithProductId'");
      console.log(action.error);
    });
    // getProductsWithCompoundId
    builder.addCase(getProductsWithCompoundId.fulfilled, (state, action) => {
      const categoryId = action.meta.arg.id;
      const categoryCompoundId = action.meta.arg.compoundId;

      const { products } = action.payload;

      let categoryIndex = state.categories.findIndex(
        ({ compoundId }) => compoundId === categoryCompoundId
      );

      categoryIndex =
        categoryIndex >= 0 ? categoryIndex : state.categories.length;
      const categoryItems = state.categories[categoryIndex]
        ? state.categories[categoryIndex].items
        : [];

      state.items = handleNewProductsInsertion(state.items, products);

      state.categories[categoryIndex] = {
        id: categoryId,
        compoundId: categoryCompoundId,
        items: [
          ...categoryItems,
          ...products.map(({ productId }) => productId),
        ],
      };
      state.requesting = false;
    });
    builder.addCase(getProductsWithCompoundId.pending, (state) => {
      state.requesting = true;
    });
    builder.addCase(getProductsWithCompoundId.rejected, (state, action) => {
      state.requesting = false;
      console.error("Houve um erro na requisição 'getProductsWithCompoundId'");
      console.log(action.error);
    });
    // getProductsWithCollectionId
    builder.addCase(getProductsWithCollectionId.fulfilled, (state, action) => {
      const { products } = action.payload;

      let collectionIndex = state.collections.findIndex(
        ({ id }) => id === action.meta.arg.id
      );

      collectionIndex =
        collectionIndex >= 0 ? collectionIndex : state.collections.length;
      const collectionItems = state.collections[collectionIndex]
        ? state.collections[collectionIndex].items
        : [];

      state.items = handleNewProductsInsertion(state.items, products);

      state.collections[collectionIndex] = {
        id: action.meta.arg.id,
        items: [
          ...collectionItems,
          ...products.map(({ productId }) => productId),
        ],
      };
      state.requesting = false;
    });
    builder.addCase(getProductsWithCollectionId.pending, (state) => {
      state.requesting = true;
    });
    builder.addCase(getProductsWithCollectionId.rejected, (state, action) => {
      state.requesting = false;
      console.error(
        "Houve um erro na requisição 'getProductsWithCollectionId'"
      );
      console.log(action.error);
    });
    // getProducts
    builder.addCase(getProducts.fulfilled, (state, action) => {
      state.requesting = false;
      state.gallery.page = action.payload.page;
      state.gallery.totalPages = action.payload.totalPages;

      action.payload.products.forEach((product) => {
        const index = state.items.findIndex(
          ({ productId }) => productId === product.productId
        );
        if (index >= 0) state.items.splice(index, 1);
        state.items.push(product);
      });
    });
    builder.addCase(getProducts.pending, (state) => {
      state.requesting = true;
    });
    builder.addCase(getProducts.rejected, (state, action) => {
      state.requesting = false;
      console.error("Houve um erro na requisição 'getProducts'");
      console.log(action.error);
    });
  },
});

export const { reducer } = slice;

export const { showProduct, toggleGallery, showRack } = slice.actions;

export {
  getProductWithProductId,
  getProductsWithCompoundId,
  getProductsWithCollectionId,
  getProducts,
};

export default slice;
