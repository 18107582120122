import axios from 'axios'

const getGallery = async () => {
  let url = `${process.env.REACT_APP_BACKEND_URL}/gallery`
  const response = await axios.get(url);
  return response;
}

export {
  getGallery
}

