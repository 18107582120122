import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Import atom
import CallToActionButton from '../../atoms/button-call-to-action';
import Dialog from '../../atoms/dialog'
import OutOfStockTag from '../../atoms/tag-out-of-stock';
import Modal from '../../molecule/modal';

// Import asset
import { ReactComponent as BigCloseIcon } from '../../../assets/icons/fechar-grande.svg';
import { ReactComponent as PhotoIcon } from '../../../assets/icons/foto.svg';
import { ReactComponent as InfoIcon } from '../../../assets/icons/informacoes.svg';
import { ReactComponent as AngleRightIcon } from '../../../assets/icons/angle-direita.svg';
import { ReactComponent as BagIcon } from '../../../assets/icons/mochila-check.svg';
import LoadingIcon  from '../../../assets/gifs/loading.gif';

//Import Store ActionCreators
import { showProduct, getProductWithProductId } from '../../../store/products';
import { addToCart } from '../../../store/cart';

//Import Hooks and Helpers
import { formatPriceWithCent } from '../../../util/format';
import useEventEmitter, { events } from '../../../hooks/useEventEmitter';

// Import style
import styles from './styles.module.scss'

export default function ProductCard(){
  const dispatchEvent = useDispatch();
  const emitEvent = useEventEmitter();

  const picture = 'picture';
  const infos = 'infos';

  const product = useSelector(
    ({ products }) => products.items.find(
      ({ productId }) => productId === products.displaying.toString() 
    )
  );

  const { displaying } = useSelector(({ products }) => products );
  
  const productSizes = product?.sizes.map(({ label }) => label);
  
  const unavailableProductSizes = product?.sizes.map(( item ) => item.isAvailable === false && item.label).filter(( invalidSize ) => invalidSize !== false ) 
  
  const selectedSizesInitialState = productSizes?.reduce(( accumulator, size ) => {
    accumulator[size] = false
    return accumulator
  }, {})

  const [ content, setContent ] = useState(picture);
  const [ selectedImage, setSelectedImage ] = useState(0);
  const [ selectedSizes, setSelectedSizes ] = useState(selectedSizesInitialState);
  const [ warningDialog, setWarningDialog ] = useState(false);
  const [ successDialog, setSuccessDialog ] = useState(false);
  const [ show, setShow ] = useState(false);
  const [ hasSupposelyRunnedOutOfStock, setHasSupposelyRunnedOutOfStock ] = useState(displaying === "52184");
      
  const showPicture = content === picture
  const showInfos   = content === infos

  let {
    productName,
    description,
    commertialOffer,
    composition,
    images,
    similars,
  } = product || {};

  const invalidCompositionTexts = ["Indefinida"]

  composition = invalidCompositionTexts.includes(composition) ? false : composition

  const productImages = images?.product;

  const { price, listPrice, numberOfInstallments } = commertialOffer || {};

  const valueOfInstallments = numberOfInstallments ? price / numberOfInstallments : false;
  
  const hasSelectedSize = selectedSizes ? Object.values(selectedSizes).includes(true) : false;

  const warningMessage  = "Você precisa escolher um tamanho antes de adicionar o produto a sua mochila."
  const successMessage  = "Você adicionou este produto a sua mochila.";
  const supposelyOutOfStockMessage = "Este produto está esgotado.";

  const showClassName   = show ? styles.show : '';
  
  const hasDiscount       = price !== listPrice
  const discountClassName = hasDiscount ? styles.discount : '';
  
  const styleButtonsClassName = hasSelectedSize ? styles.checked : '';
  
  const isLoading = !Boolean(product);
  
  const bagIcon = <BagIcon className={styles.dialogIcon} />

  const similarsIds = similars?.map(({ productId }) => {
    return productId !== product.productId ? productId : false;
  }).filter(Boolean) || [];

  const similarsObjects = useSelector(
    ({ products }) => {
      return products.items.filter(( item ) => similarsIds.includes( item.productId ));
    }
  )
  
  let outOfStock = product?.sizes.filter(item => item.isAvailable === true).length ? false : true;

  useEffect(() => {
    Promise.resolve(
      dispatchEvent(getProductWithProductId(displaying))
    ).then(( data ) => {
      if(data.payload.products.length === 0) {
        setHasSupposelyRunnedOutOfStock(true);
      }
    });
  }, [ dispatchEvent, displaying ]);

  useEffect(() => {
    setTimeout(() => { 
      setShow(true);
    }, 10);
  }, [ setShow ]);

  useEffect(() => {

    const loadSimilars = similarsObjects.length !== similarsIds.length;
    
    if(loadSimilars){
      similarsIds.forEach(( id ) => {
        dispatchEvent(getProductWithProductId(id));
      });
    }
    //eslint-disable-next-line
  }, [ dispatchEvent, product ]);

  useEffect(() => {
    if (product) {
      setSelectedSizes(selectedSizesInitialState);
    } 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product])

  function hideProduct(){ 
    setShow(false);
    setTimeout(() => {
      dispatchEvent(showProduct({ productId: 0 }));
    }, 300);
  }

  function changeImage(direction) {
    const currentIndex = selectedImage
    const firstIndex = 0
    const finalIndex = productImages.length - 1

    const nextIndex = currentIndex + direction > finalIndex ? firstIndex : currentIndex + direction < firstIndex ? finalIndex : currentIndex + direction
    setSelectedImage(nextIndex)
  }

  function dismarkButtonSizes() {
    const currentSelected = { ...selectedSizes }
    for (const property in currentSelected) {
      currentSelected[property] = false;
    }
    setSelectedSizes(currentSelected);
  }

  function toggleSize(label) {
    const currentSelected = { ...selectedSizes }
    for (const property in currentSelected) {
      if (property !== label){
        currentSelected[property] = false;
      }
    }    
    currentSelected[label] = currentSelected[label] ? false : true
    
    setSelectedSizes(currentSelected)
  }


  function createMarkup() {
    var _description = description
                       .replaceAll(/<a[^>]*>(.*?)<\/a>/g, "$1")
                       .replaceAll(/<script[^>]*>(.*?)<\/script>/g, "")
    return {__html: `${_description}`};
  }
  
  function selectProduct() {

    if(!hasSelectedSize) {
      setWarningDialog(true);
      return;
    } 

    const selectedSizesLabels = Object.keys(selectedSizes).filter((key) => selectedSizes[key])
    const selectedSizesList = selectedSizesLabels.map((size) => {
      let selectedSizeObj = {
        ...Object.values(product.sizes).find(( sizeObj ) => sizeObj.label === size),
        productId: product.productId,
        commertialOffer: product.commertialOffer,
        amount: 1
      }
      return selectedSizeObj
    });
  
    selectedSizesList.forEach((product) => {
      dispatchEvent(addToCart(product))
      emitEvent(events.onCartAdd, { productId: product.productId })
    });
    
    setSuccessDialog(true);
    setSelectedSizes(selectedSizesInitialState);
  }

  function formOnClick(e) {
    e.stopPropagation();
  }

  function dialogSuccessOnConfirm(e){
    e.stopPropagation();
    setSuccessDialog(false);
    hideProduct();
  }

  function dialogWarningOnConfirm(e){
    e.stopPropagation();
    setWarningDialog(false);
  }

  return (
    <Modal onClick={hideProduct} className={`${styles.modal} ${showClassName}`}>
      { !hasSupposelyRunnedOutOfStock && 
        <form className={ `${styles.card} ${showClassName}` } onClick={formOnClick}>
          <header className={ styles.header}>
            <button type='button' className={ styles.closeButton } onClick={hideProduct}>
              <BigCloseIcon/>
            </button>
            {
              content === picture ? (
                <button onClick={() => setContent(infos)} className={ styles.buttonInfos } type='button'>
                  <InfoIcon/>
                </button>
              ) : (
                <button onClick={() => setContent(picture)} className={ styles.buttonPictures } type='button'>
                  <PhotoIcon/>
                </button>
              )
            }
          </header>

          <section className={ styles.section }>
            <>
              {isLoading ? (
                <>
                  <div className={ styles.loading }>
                    <img src={LoadingIcon} alt="loading..." className={ styles.loadingIcon }/>
                  </div>
                </>
              ) : (
                <>
                  {showPicture && 
                    <div className={ styles.photos }>
                      {productImages.map(({ imageUrl, imageText }, index) => {
                        const displayClass = index === selectedImage ? styles.imageShown : ""
                        return (
                          <img key={imageUrl} className={`${styles.productImage} ${displayClass}`} src={imageUrl} alt={imageText} />
                        )
                      })}
                      <div className={styles.changeImage}>
                        <button onClick={() => changeImage(-1)} className={`${styles.changeImageLeft}`} type='button'>
                          <AngleRightIcon/>
                        </button>
                      
                        <button onClick={() => changeImage(1)} className={`${styles.changeImageRight}`} type='button'>
                          <AngleRightIcon/>
                        </button>
                      </div>
                    </div>
                  }
                  {showInfos &&
                    <div className={ styles.infosProportion }>
                      <div className={ styles.infosInternalPadding }>
                        <div className={ styles.infosScrollableArea }>
                          <div className={ styles.productTitle }>{productName}</div>
                          <div dangerouslySetInnerHTML={createMarkup()} />
                          {composition && <><hr/>{composition}</>}
                        </div>
                      </div>
                    </div>
                  }
                  <div className={styles.sizesMenu}>
                    {productSizes.map( sizeLabel => {
                      const checked = selectedSizes ? selectedSizes[sizeLabel] : false;
                      const checkedClass = checked ? styles.checked : ""
                      const isDisabled = unavailableProductSizes.includes(sizeLabel)
                      const disableClass = isDisabled ? styles.disable : ""
                      const sizeLabelText = sizeLabel.split(" ")
                      const hasTwoTexts = sizeLabelText.length > 1
                      const twoTextsClassName = hasTwoTexts ? styles.reduceLabelSize : ""

                      return (
                        <label key={sizeLabel} htmlFor="size" className={`${styles.buttonSize} ${checkedClass} ${disableClass}`}>
                          {sizeLabelText.map(( text, index ) => {
                            const slash = index < sizeLabelText.length - 1 ? '/' : ''
                            return <div key={text} className={`${styles.sizeText} ${twoTextsClassName}`}>{text}{slash}</div> 
                          })}
                          <input disabled={isDisabled} name="size" checked={checked} type="checkbox" onChange={()=>toggleSize(sizeLabel)} className={`${styles.sizeCheckbox}`} />
                        </label>
                      )
                    })}
                  </div>
                </>
              )}
            </>
          </section>

          <footer className={ styles.footer }>
            <p title={productName} className={ styles.productTitle }>{productName}</p>
            
            <div className={styles.divPrice}>
              {!isLoading && 
                <>
                  {outOfStock && <OutOfStockTag className={ styles.outOfStock } /> }
                  {!outOfStock && 
                    <p className={ `${styles.productPrice} ${discountClassName}` }>
                      {formatPriceWithCent(price)}
                      {hasDiscount && 
                        <span className={ styles.productPriceWithoutDiscount }>{formatPriceWithCent(listPrice)}</span>
                      }
                      {(numberOfInstallments > 1) && 
                        <span className={ styles.installments }>&nbsp;ou {numberOfInstallments}x de {formatPriceWithCent(valueOfInstallments)}</span>
                      }
                    </p>
                  }
                </>
              }
            </div>
            <div className={styles.divSimilars}>
              {(similars || []).map( similar  => {
                const { productId, variation  } = similar
                return (
                  <button 
                    type="button" 
                    className={ styles.buttonSimilarImage } 
                    key={ productId }
                    onClick={ () => { 
                      dispatchEvent(showProduct({ productId })); 
                      dismarkButtonSizes();
                      setSelectedImage(0);
                    }}
                  >
                    <img key={variation.imageUrl} className={styles.similarImage} src={variation.imageUrl} alt={variation.imageText} />
                  </button>
                )
              })}
            </div>
            <div className={styles.divCallToAction}> 
              <CallToActionButton onClick={selectProduct} disabled={outOfStock} className={`${styles.callToAction} ${styleButtonsClassName}`}>Adicionar à Mochila</CallToActionButton>                 
            </div>
          </footer>
        </form>
      }
      
      {hasSupposelyRunnedOutOfStock && 
        <Dialog onClick={(e) => e.stopPropagation()} className={styles.outOfStockDialog} message={supposelyOutOfStockMessage} />
      }

      {warningDialog && 
        <Modal onClick={dialogWarningOnConfirm}>
          <Dialog type="warning" message={warningMessage}/>
        </Modal>
      }
      {successDialog &&
        <Modal onClick={dialogSuccessOnConfirm}>
          <Dialog message={successMessage} icon={bagIcon}/>
        </Modal>
      }
    </Modal>
  )
}