import { useDispatch } from "react-redux";

import { startTour } from "../../../store/virtual-tour";

import useEventEmitter, { events } from "../../../hooks/useEventEmitter";

import styles from "./styles.module.scss";

const isMaintenance = Boolean(process.env.REACT_APP_MAINTENANCE);

export default function Preload({ onClick }) {
  const dispatchEvent = useDispatch();
  const emitEvent = useEventEmitter();

  const maintenanceClassName = isMaintenance ? styles.maintenance : "";

  function handleOnClick(e) {
    if (isMaintenance) return;
    dispatchEvent(startTour());
    emitEvent(events.onTourStart);
    emitEvent(events.onSplashScreenBackgroundClick);
    if (onClick) onClick(e);
  }

  return (
    <div
      className={`${styles.preload} ${maintenanceClassName}`}
      onClick={handleOnClick}
    ></div>
  );
}
