export const { format: formatPrice } = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
});

export const formatWithoutCent = num => {
    const monetary = formatPrice(num);
    return monetary.split(',')[0];
};
export const formatWithCent = num => {
    const monetary = formatPrice(num);
    return monetary;
};

export const formatPriceWithCent = num => {
    if(Number.isInteger(num)){
        return formatWithoutCent(num);
    } else {
        return formatWithCent(num);
    }
}