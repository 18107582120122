import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCheckoutSimulation } from "../../services/Checkout";

const namespace = "cart";
const localStorageItem = "cart_items";

const localStorageItems = localStorage.getItem(localStorageItem);
const items = localStorageItems ? JSON.parse(localStorageItems) : [];

const simulateCheckout = createAsyncThunk(
  `${namespace}/simulateCheckout`,
  async (items) => {
    const response = await getCheckoutSimulation(items);
    response.data.items = response.data.items.map((item) => {
      return {
        ...item,
        availableQuantity: items.find(({ id }) => id === item.skuId)
          .availableQuantity,
      };
    });
    return response.data;
  }
);

const initialState = {
  items,
  commertialOffer: { total: 0, listTotal: 0, numberOfInstallments: 1 },
  displaying: false
};

const slice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    toggleCart: (state) => {
      state.displaying = !state.displaying;
    },
    addToCart: (state, action) => {
      const skuAlreadyAdded = state.items.findIndex(
        (item) => item.skuId === action.payload.skuId
      );

      if (skuAlreadyAdded === -1) {
        state.items.push(action.payload);
      } else {
        state.items[skuAlreadyAdded].amount = state.items[skuAlreadyAdded].amount + 1
      }

      localStorage.setItem(localStorageItem, JSON.stringify(state.items));
    },
    increaseItemAmount: (state, action) => {
      const itemIndex = state.items.findIndex(
        (item) => item.skuId === action.payload.skuId
      );
      state.items[itemIndex].amount++;
      localStorage.setItem(localStorageItem, JSON.stringify(state.items));
    },
    decreaseItemAmount: (state, action) => {
      const itemIndex = state.items.findIndex(
        (item) => item.skuId === action.payload.skuId
      );
      if (state.items[itemIndex].amount > 1) {
        state.items[itemIndex].amount--;
        localStorage.setItem(localStorageItem, JSON.stringify(state.items));
      }
    },
    removeItemFromCart: (state, action) => {
      const itemIndex = state.items.findIndex(
        (item) => item.skuId === action.payload.skuId
      );
      state.items.splice(itemIndex, 1);
      localStorage.setItem(localStorageItem, JSON.stringify(state.items));
      if(!state.items.length){
        state.commertialOffer = initialState.commertialOffer
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(simulateCheckout.fulfilled, (state, action) => {
      state.commertialOffer = action.payload.commertialOffer;
      action.payload.items.forEach((checkoutItem) => {
        const itemIndex = state.items.findIndex(
          (item) => item.skuId === checkoutItem.skuId
        );
        if (state.items[itemIndex]) {
          state.items[itemIndex].availableQuantity =
            checkoutItem.availableQuantity;
          state.items[itemIndex].isAvailable = !!checkoutItem.isAvailable;
          state.items[itemIndex].commertialOffer.listPrice =
            checkoutItem.listPrice;
          state.items[itemIndex].commertialOffer.price = checkoutItem.price;
          if (state.items[itemIndex].amount > checkoutItem.availableQuantity)
            state.items[itemIndex].amount = checkoutItem.availableQuantity;
        }
      });
      state.requesting = false;
    });
    builder.addCase(simulateCheckout.pending, (state) => {
      state.requesting = true;
    });
    builder.addCase(simulateCheckout.rejected, (state) => {
      state.requesting = false;
      console.error("Houve um erro na requisição 'simulateCheckout'");
    });
  },
});

export const { reducer } = slice;

export const {
  toggleCart,
  addToCart,
  increaseItemAmount,
  decreaseItemAmount,
  removeItemFromCart,
} = slice.actions;

export { simulateCheckout };

export default slice;
