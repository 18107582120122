import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCheckoutParams } from "../../services/Checkout";

const namespace = "checkout";

const getCheckoutVariables = createAsyncThunk(
  `${namespace}/getCheckoutVariables`,
  async () => {
    const response = await getCheckoutParams();
    return response.data;
  }
);

const slice = createSlice({
  name: namespace,
  initialState: {
    checkoutParams: {},
    requesting: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    // getCheckoutVariables
    builder.addCase(getCheckoutVariables.fulfilled, (state, action) => {
      state.checkoutParams = action.payload;
      state.requesting = false;
    });
    builder.addCase(getCheckoutVariables.pending, (state) => {
      state.requesting = true;
    });
    builder.addCase(getCheckoutVariables.rejected, (state) => {
      state.requesting = false;
      console.error("Houve um erro na requisição 'getCheckoutVariables'");
    });
  },
});

export const { reducer } = slice;

export { getCheckoutVariables };

export default slice;
