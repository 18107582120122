import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";

import Cart from "./components/organism/cart";
import Menu from "./components/organism/menu";
import Preload from "./components/organism/preload";
import ProductCard from "./components/molecule/card-product";
import ProductsGallery from "./components/organism/gallery-products";

import useRadio from "./hooks/useRadio";
import useEventEmitter, { events } from "./hooks/useEventEmitter";

import {
  getProducts,
  showProduct,
  showRack,
  toggleGallery,
} from "./store/products";

import { getCollectionsList } from "./store/collections";
import { getCategoriesList } from "./store/categories";
import { getCheckoutVariables } from "./store/checkout";
import { toggleCart } from "./store/cart";
import { setLastRadioStatus } from "./store/radio";
import { getGalleryInfo } from "./store/gallery";

export default function AppTour() {
  const dispatchEvent = useDispatch();
  const emitEvent = useEventEmitter();

  const [isDisplayingProduct, isDisplayingGallery, rackTaxonomy] = useSelector(
    ({ products }) => {
      const { displaying, displayingGallery, displayingRack } = products;
      return [Boolean(displaying), displayingGallery, displayingRack];
    }
  );

  const isDisplayingCart = useSelector(({ cart }) => cart.displaying);
  const { tourStarted } = useSelector(({ virtualTour }) => virtualTour);
  const isPlayingRadio = useSelector(({ radio }) => radio.playing);
  const lastRadioStatus = useSelector(({ radio }) => radio.lastRadioSatus);
  const allCategories = useSelector(({ categories }) => categories.items);
  const allCollections = useSelector(({ collections }) => collections.items);

  const shouldDisplayGallery = isDisplayingGallery && !rackTaxonomy;
  const isDisplayingRack = isDisplayingGallery && rackTaxonomy;

  const radio = useRadio();

  function handlePreloadOnClick(event) {
    radio.play(event);
  }

  function handleMenuOnSoundClick(event) {
    if (isPlayingRadio) {
      radio.pause(event);
      emitEvent(events.onSoundOff);
    }
    if (!isPlayingRadio) {
      radio.play(event);
      emitEvent(events.onSoundOn);
    }
  }

  useEffect(() => {
    dispatchEvent(getProducts());
    dispatchEvent(getCheckoutVariables());

    Promise.all([
      dispatchEvent(getCategoriesList()),
      dispatchEvent(getCollectionsList()),
    ]).then(() => {
      dispatchEvent(getGalleryInfo());
    });
  }, [dispatchEvent]);

  window.SOMA3D = {
    displayProduct: (productId = "51562", fallbackCategory = false) => {
      dispatchEvent(showProduct({ productId, fallbackCategory }));
      emitEvent(events.onProductOpen, { productId });
    },
    displayGallery: () => {
      dispatchEvent(toggleGallery());
    },
    displayRack: (taxonomy = { taxonomyType: "category", id: 449 }) => {
      const taxonomies = [].concat(allCollections, allCategories);

      const selectedTaxonomy = taxonomies.find(({ id, taxonomyType }) => {
        return taxonomy.id === id && taxonomy.taxonomyType === taxonomyType;
      });
      if (selectedTaxonomy) {
        dispatchEvent(showRack(selectedTaxonomy));
        emitEvent(events.onRackOpen, { categoryCompoundId: taxonomy.id });
      }
      dispatchEvent(toggleGallery());
    },
    displayBag: () => {
      dispatchEvent(toggleCart());
    },
    onPlayVideo: () => {
      emitEvent(events.onVideoStart);
      dispatchEvent(setLastRadioStatus(isPlayingRadio));
      radio.pause();
    },
    onCloseVideo: () => {
      if (lastRadioStatus) {
        radio.play();
      }
    },
    onBubbleAction: () => {
      emitEvent(events.onBubbleAction);
    },
    onCouponAction: () => {
      emitEvent(events.onCouponAction);
    },
    onGuidedTourStart: () => {
      emitEvent(events.onGuidedTourStart);
    },
  };

  return (
    <>
      {tourStarted && (
        <>
          <Menu onSoundClick={handleMenuOnSoundClick} />
          {shouldDisplayGallery && <ProductsGallery />}
          {isDisplayingRack && (
            <ProductsGallery forceTaxonomy={rackTaxonomy} noFilter={true} />
          )}
          {isDisplayingProduct && <ProductCard />}
          {isDisplayingCart && <Cart />}
        </>
      )}

      {!tourStarted && <Preload onClick={handlePreloadOnClick} />}
    </>
  );
}
