import { configureStore } from "@reduxjs/toolkit"

import { reducer as products } from './products'
import { reducer as categories } from './categories'
import { reducer as collections } from './collections'
import { reducer as checkout } from './checkout'
import { reducer as cart } from './cart'
import { reducer as gallery } from './gallery'
import { reducer as radio } from './radio'
import { reducer as virtualTour } from './virtual-tour'

export default configureStore({
  reducer: {
    products,
    categories,
    collections,
    checkout,
    cart,
    gallery,
    radio,
    virtualTour
  },
  devTools: !process.env.NODE_ENV || process.env.NODE_ENV === "development",
});
