import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import fscreen from "fscreen";

import { ReactComponent as FullScreenIcon } from '../../../assets/icons/tela-cheia.svg'
import { ReactComponent as LeaveFullScreenIcon } from '../../../assets/icons/tela-cheia-sair.svg'
import { ReactComponent as CartIcon } from '../../../assets/icons/mochila.svg'
import { ReactComponent as GalleryIcon } from '../../../assets/icons/galeria.svg'
import { ReactComponent as SoundOn } from '../../../assets/icons/sound-on.svg'
import { ReactComponent as SoundOff } from '../../../assets/icons/sound-off.svg'

import { toggleCart } from "../../../store/cart";
import { toggleGallery } from "../../../store/products";

import useEventEmitter, { events } from "../../../hooks/useEventEmitter";

import styles from './styles.module.scss';

export default function Menu({ onSoundClick }){
  
  const dispatchEvent = useDispatch();
  const emitEvent = useEventEmitter();
  
  const playingRadio = useSelector(({ radio }) => radio.playing );

  const [ isDisplayingCart, cartItems ] = useSelector(({ cart }) => [ cart.displaying, cart.items ]);
  
  const isDisplayingGallery = useSelector(({ products }) => products.displayingGallery )

  const [ fullscreen, setFullscreen ] = useState(false)

  const cartItemsAmount = cartItems.reduce(( acc, item ) => {
    return acc + item.amount
  }, 0)
  
  useEffect(() => {
    fscreen.addEventListener('fullscreenchange', function(){
      setFullscreen(Boolean(fscreen.fullscreenElement))
    });
  }, []);

  function fullScreenOnClick(){
    const isOnFullscreen = Boolean(fscreen.fullscreenElement);
    if(isOnFullscreen){
      fscreen.exitFullscreen();
    } else {
      fscreen.requestFullscreen(document.documentElement);
    }
  }

  function cartOnClick(){
    dispatchEvent(toggleCart());
  }

  function galleryOnClick(){
    if(!isDisplayingGallery) {
      emitEvent(events.onGalleryOpen);
    }
    dispatchEvent(toggleGallery());
  }

  function radioOnClick(e){
    onSoundClick(e)
  }

  const fullscreenAction = fullscreen ? 'Sair do' : 'Entrar em'
  const radioAction = playingRadio ? 'Desativar' : 'Ativar'
  const cartAction = isDisplayingCart ? 'Fechar' : 'Abrir'
  
  const hasItemsOnCart = Boolean(cartItemsAmount);

  const hasFullscreenSupport = fscreen.fullscreenEnabled;

  return (
    <aside className={styles.menu}>
      {hasFullscreenSupport && 
        <button title={`${fullscreenAction} Modo FullScreen`} className={styles.button} type="button" onClick={fullScreenOnClick}>
          {!fullscreen && <FullScreenIcon />}
          {fullscreen && <LeaveFullScreenIcon />}
        </button>
      }
      <button title={`${cartAction} a Mochila`} className={styles.button} type="button" onClick={cartOnClick}>
        {hasItemsOnCart && 
          <div className={styles.cartItemsAmount}>{cartItemsAmount}</div>
        }
        <CartIcon/>
      </button>
      <button title='Abrir a Galeria' className={styles.button} type="button" onClick={galleryOnClick}>
        <GalleryIcon />
      </button>
      <button title={`${radioAction} a Radio Farm`} className={styles.button} type="button" onClick={radioOnClick}>
        {playingRadio && <SoundOn />}
        {!playingRadio && <SoundOff />}
      </button>
    </aside>
  )
}