import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import CallToActionButton from "../../atoms/button-call-to-action";
import CartProductCard from "../../molecule/card-product-cart";

import { ReactComponent as CloseIcon } from "../../../assets/icons/fechar-pequeno.svg";

import { toggleCart, simulateCheckout, removeItemFromCart } from "../../../store/cart";
import { getProductWithProductId } from "../../../store/products";

import { formatPrice } from "../../../util/format";
import useEventEmitter, { events } from "../../../hooks/useEventEmitter";

import styles from "./styles.module.scss";
import Modal from "../../molecule/modal";

export default function Cart() {
  const dispatchEvent = useDispatch();
  const emitEvent = useEventEmitter();

  const [ show, setShow ] = useState(false);
  const cartItems = useSelector(({ cart }) => cart.items);
  const products = useSelector(({ products }) => products.items);
  
  const checkoutNumberOfInstallments = useSelector(
    ({ cart }) => cart.commertialOffer.numberOfInstallments
  );
  const { total, listTotal } = useSelector(({ cart }) => cart.commertialOffer);
  const { salesChannel, url } = useSelector(
    ({ checkout }) => checkout.checkoutParams
  );

  const isEmpty = !cartItems.length;

  const notLoadedItems = cartItems.reduce((acc, item) => {
    const product = products.find(
      (product) => product.productId === item.productId
    );
    if (product) return acc;
    acc.push(item.productId);
    return acc;
  }, []);

  const cartItemsToRender = cartItems.filter(
    (item) => !notLoadedItems.includes(item.productId)
  );

  const numberOfInstallments = checkoutNumberOfInstallments
    ? checkoutNumberOfInstallments
    : cartItems.reduce(
        (acc, item) => Math.max(item.commertialOffer.numberOfInstallments, acc),
        1
      );

  const price = listTotal
    ? listTotal
    : cartItems.reduce(
        (acc, item) =>
          item.commertialOffer.priceWithoutDiscount * item.amount + acc,
        0
      );

  const installmentsPrice = price / numberOfInstallments;
  const InstallmentPriceMessage = () => (
    <>
      ou {numberOfInstallments}x de{" "}
      <span className={styles.priceValue}>
        {formatPrice(installmentsPrice)}
      </span>
    </>
  );

  const finalPrice = total
    ? total
    : cartItems.reduce(
        (acc, item) => item.commertialOffer.price * item.amount + acc,
        0
      );
  const installmentsFinalPrice = finalPrice / numberOfInstallments;
  const InstallmentFinalPriceMessage = () => (
    <>
      ou {numberOfInstallments}x de{" "}
      <span className={styles.priceValue}>
        {formatPrice(installmentsFinalPrice)}
      </span>
    </>
  );

  const productsParams = cartItems.reduce(
    (acc, item) =>
      `${acc}&sku=${item.skuId}&qty=${item.amount}&seller=${item.seller}`,
    ""
  );

  const searchParams = new URLSearchParams(window.location.search);
  const cod = searchParams.get("cod");
  const codParams = cod ? `&utm_campaign=${cod}` : "";

  const checkoutURL = `${url}/checkout/cart/add?sc=${salesChannel}${productsParams}${codParams}`;

  const showClassName = show ? styles.show : "";

  function closeCart(e) {
    e.stopPropagation();
    setShow(false);
    setTimeout(() => {
      dispatchEvent(toggleCart());
    }, 300);
  }

  function handleDoCheckout(){
    emitEvent(events.onCheckoutDo)
  }

  useEffect(() => {
    if(cartItems.length === 0) return
    dispatchEvent(
      getProductWithProductId(cartItems.map((cartItem) => cartItem.productId))
    ).then((data) => {
      const items = cartItems.reduce((filtered, cartItem) => {
        const product = data.payload.products.find(
          ({ productId }) => {
            return productId === cartItem.productId
          }
        );
        if (product) {
          filtered.push({
            id: cartItem.skuId,
            quantity: cartItem.amount,
            seller: cartItem.seller,
            availableQuantity: product.sizes.find(
              ({ skuId }) => skuId === cartItem.skuId
            ).availableQuantity,
          });
        } else {
          dispatchEvent(removeItemFromCart(cartItem)).then(() => {
            if(items.length > 0){
              dispatchEvent(simulateCheckout(items));
            }
          });
        }
        return filtered;
      }, []);
    });
  }, [dispatchEvent, cartItems]);

  useEffect(() => {
    setShow(true);
  }, [setShow]);

  return (
    <Modal onClick={closeCart} className={`${styles.modal} ${showClassName}`}>
      <section onClick={(e) => e.stopPropagation()}className={`${styles.cart} ${showClassName}`}>
        <header className={styles.header}>
          <h2 className={styles.title}>Minha Mochila</h2>
          <button onClick={closeCart} className={styles.closeButton}>
            <CloseIcon />
          </button>
          <hr />
        </header>
        <section className={styles.middleSection}>
          {isEmpty && (
            <div className={styles.emptyMessage}>
              <h3>Sua mochila está vazia.</h3>
              <p>
                Continue navegando pela loja ou dê uma passadinha em nossa
                galeria, temos certeza que você encontrará a peça perfeita para
                você ;)
              </p>
            </div>
          )}
          {!isEmpty && (
            <div className={styles.productsList}>
              {cartItemsToRender.map((item) => {
                return (
                  <CartProductCard
                    className={styles.productCard}
                    key={item.skuId}
                    item={item}
                  />
                );
              })}
            </div>
          )}
        </section>
        <section className={styles.bottomSection}>
          <p className={styles.bottomSectionTopText}>
            *Aplicação de cupons de desconto será feita na área de checkout.
          </p>
          {cod && (
            <p className={styles.bottomSectionBottomText}>
              **O cupom ({cod}) será adicionado no checkout
            </p>
          )}
        </section>
        <footer className={styles.footer}>
          <div className={`${styles.prices} ${styles.subtotal}`}>
            <h3>Subtotal</h3>
            <div>
              <span className={styles.priceValue}>{formatPrice(price)} </span>
              {!isEmpty && <InstallmentPriceMessage />}
            </div>
          </div>
          <div className={`${styles.prices} ${styles.total}`}>
            <h3>Total</h3>
            <div>
              <span className={`${styles.priceValue} ${styles.finalPrice}`}>
                {formatPrice(finalPrice)}{" "}
              </span>
              {!isEmpty && <InstallmentFinalPriceMessage />}
            </div>
          </div>
          <a href={checkoutURL} onClick={handleDoCheckout} target="_blank" rel="noopener noreferrer">
            <CallToActionButton
              disabled={isEmpty}
              className={`${styles.callToAction}`}
            >
              Finalizar a compra
            </CallToActionButton>
          </a>
        </footer>
      </section>
    </Modal>
  );
}
