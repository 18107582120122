import axios from "axios";

const getCheckoutParams = async () => {
  let url = `${process.env.REACT_APP_BACKEND_URL}/checkout`;
  const response = await axios.get(url);
  return response;
};

const getCheckoutSimulation = async (items) => {
  let url = `${process.env.REACT_APP_BACKEND_URL}/checkout/simulation`;
  const response = await axios.post(url, { items: items });
  return response;
};

export { getCheckoutParams, getCheckoutSimulation };
