import styles from "./styles.module.scss";

export default function OutOfStockTag(props) {
  return (
    <div
      className={`${styles.outOfStock} ${
        props.className ? props.className : ""
      }`}
    >
      sem estoque
    </div>
  );
}
