import { ReactComponent as ArrowDown } from '../../../assets/icons/angle-down.svg';

import styles from './styles.module.scss';

export default function Select({ onChange, currentValue, options, open, setOpen, className }){
  
  const { value, label } = currentValue;
  
  function onClick(e){
    setOpen(!open);
    if(onChange) onChange(e.target.value);
  }

  function toggleOpen(){
    setOpen(!open);
  }

  const openClassName = open ? styles.open : '';

  return (
    <div className={`${styles.select} ${openClassName} ${className || ''}`}>
      <button type='button' className={`${styles.selectedValue} ${openClassName}`} onClick={toggleOpen} value={value}>
        <span className={styles.label}>{label}</span>
        <ArrowDown className={styles.arrow}/>
      </button>
      <div className={`${styles.optionsWrapper} ${openClassName}`}>
        {options.map(({value, label}) => {
          return <button className={styles.option} key={value} type="button" onClick={onClick} value={value}>{label}</button>
        })}
      </div>
    </div>
  );
}